<template>
    <div class="ml-10 mr-10">

        <TitleBar :loading="loading" :text="this.$t('company.settings.oauth')"></TitleBar>

        <v-card :loading="loading ? 'secondary' : false" class="mb-2" outlined>
            <v-form @submit.prevent="addConfig">
                <v-card-text>
                    <div class="text-overline mb-4">
                        <v-icon>mdi-account-child</v-icon>
                        {{ $t("company.oauth.providers.new") }}
                    </div>
                    <v-row class="mt-5">
                        <v-col cols="12" md="6">
                            <!--                                 v-model="name"
                                                                :disabled="loading"
                                                                :error-messages="nameErrors"
                                                                 @blur="$v.name.$touch()"
                                                                @input="$v.name.$touch()"-->
                            <v-text-field
                                v-model="client_id"
                                :disabled="loading"
                                :label="$t('company.oauth.client_id')"
                                hide-details
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="client_secret"
                                :disabled="loading"
                                :label="$t('company.oauth.client_secret')"
                                hide-details
                                outlined
                                type="password"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-select
                        v-model="provider"
                        :disabled="loading"
                        :items="providers"
                        :label="$t('company.oauth.select_provider')"
                        class="mt-5"
                        hide-details
                        outlined
                    ></v-select>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        color="secondary"
                        text
                        type="submit"
                    >
                        {{ $t("company.oauth.add") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

        <v-divider v-if="configs.length > 0" class="mb-5 mt-5"></v-divider>

        <v-card v-for="conf in configs" :key="conf.uuid" :loading="loading ? 'secondary' : false" class="mb-2" outlined>
            <v-form @submit.prevent="formSubmit(conf.uuid)">
                <v-card-text class="pb-0">
                    <div class="text-overline mb-4">
                        <v-icon>mdi-{{ getProviderIcon(conf.provider) }}</v-icon>
                        {{ $t("company.oauth.providers." + conf.provider) }}
                    </div>
                    <v-row class="mt-5">
                        <v-col cols="12" md="6">
                            <!--                                 v-model="name"
                                                                :disabled="loading"
                                                                :error-messages="nameErrors"
                                                                 @blur="$v.name.$touch()"
                                                                @input="$v.name.$touch()"-->
                            <v-text-field
                                v-model="conf.client_id"
                                :disabled="loading"
                                :label="$t('company.oauth.client_id')"
                                hide-details
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="conf.client_secret"
                                :disabled="loading"
                                :label="$t('company.oauth.client_secret')"
                                hide-details
                                outlined
                                type="password"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        color="secondary"
                        text
                        type="submit"
                    >
                        {{ $t("company.oauth.update") }}
                    </v-btn>
                    <v-btn
                        :disabled="loading"
                        :loading="loading"
                        color="red"
                        text
                        @click="deleteElement(conf.uuid)"
                    >
                        {{ $t("company.oauth.delete") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

        <v-overlay
            :absolute="true"
            :opacity="0.1"
            :value="loading"
        >
            <v-progress-circular
                color="purple"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>

import Accounting from "@/helpers/Accounting";
import Utils from "@/helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "OAuthConfig",
    components: {TitleBar},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.settings"),
                to: "/company/settings",
                exact: true
            },
            {
                text: this.$t("company.settings.oauth"),
                to: "/company/settings/oauth",
                exact: true
            }
        ]);

        this.loadConfigs();

        let providers = ["google", "azure-ad"];
        for (const provider of providers) {
            this.providers.push({text: this.$t("company.oauth.providers." + provider), value: provider});
        }
    },
    data() {
        return {
            loading: true,
            configs: [],

            providers: [],

            provider: null,
            client_id: null,
            client_secret: null
        };
    },
    methods: {
        loadConfigs() {
            this.configs = [];
            this.loading = true;
            Accounting.get("/oauth?fields=uuid,provider,client_id,client_secret&per_page=-1")
                .then(resp => {
                    this.configs = resp.data.config;
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formSubmit(id) {
            this.$store.commit("SET_ALERTS", []);
            this.loading = true;

            let data = {};
            for (const config of this.configs) {
                if (config.uuid === id) {
                    data = config;
                }
            }

            Accounting.put("/oauth/" + id, {
                    provider: data.provider,
                    client_id: data.client_id,
                    client_secret: data.client_secret
                })
                .then(() => {
                    this.loadConfigs();
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // eslint-disable-next-line no-unused-vars
        deleteElement(id) {
            this.$swal({
                title: this.$t("company.oauth.delete_title"),
                text: this.$t("company.oauth.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("company.oauth.delete_yes"),
                cancelButtonText: this.$t("company.oauth.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/oauth/" + id)
                        .then(() => {
                            this.loading = false;
                            this.loadConfigs();
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        getProviderIcon(prov) {
            switch (prov) {
                case "google":
                    return "google";
                case "azure-ad":
                    return "microsoft-azure";
                default:
                    return "login";
            }
        },
        addConfig() {
            this.$store.commit("SET_ALERTS", []);
            this.loading = true;
            Accounting.post("/oauth", {
                    provider: this.provider,
                    client_id: this.client_id,
                    client_secret: this.client_secret
                })
                .then(() => {
                    this.loadConfigs();
                })
                .catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>

</style>